body {
  background-color: black;
  user-select: none;
}

.App {
  text-align: center;
  width: 300px;
  height: 500px;
  border: 5px solid white;
  border-radius: 1px;
  margin: 0 auto;
  background-color: rgb(42, 42, 49);
  color: white;
  position: relative;
  overflow: hidden;
  font-family: "Quicksand", sans-serif;
}

button:hover {
  cursor: pointer;
}

img {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

/* start screen */

#info {
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#start-screen h1,
#end-screen h2 {
  font-family: "Fredoka One", sans-serif;
  letter-spacing: 4px;
}

#info h1 img {
  position: relative;
  width: 25px;
  height: 25px;
  top: 4px;
  margin-right: 3px;
}

#info p {
  width: 80%;
  margin: 0 auto;
  font-size: 0.9em;
  margin-bottom: 5px;
}

#switch {
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  padding: 5px;
  border-radius: 10px;
  font-size: 0.6em;
}

#switch i {
  font-size: 2em;
  margin: 0 8px;
}

#switch .clickable:hover {
  cursor: pointer;
}

#credits {
  font-size: 0.6em;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

#credits a {
  color: lightblue;
}

button {
  width: 100px;
  font-family: "Fredoka One", sans-serif;
  letter-spacing: 2px;
  padding: 5px 0;
  background-color: white;
  border: none;
  border-radius: 10px;
}

button:hover {
  transform: scale(1.05);
}

/* .touch-cover {
  position: absolute;
  width: 100%;
  height: 100%;
} */

#game {
  height: 100%;
  background: url("./assets/stars.png");
}

#game-header {
  position: absolute;
  width: 280px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(96, 115, 155);
  padding: 10px;
  margin: 0 auto;
}

#lives {
  display: flex;
  align-items: center;
}

#lives img {
  margin-left: 4px;
  width: 20px;
  height: 20px;
}

#user img {
  position: absolute;
  bottom: 10px;
  transform: translateX(-50%) rotate(-45deg);
  width: 57px; /* 80px when rotated */
  height: 57px; /* 80px when rotated */
}

.virus img {
  position: absolute;
}

#crash {
  position: absolute;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);
  z-index: 100;
}

#end-screen {
  position: absolute;
  transform: translateX(-50%);
  top: 30%;
  left: 50%;
  width: 75%;
  border: 10px solid white;
  background-color: rgb(96, 115, 155);
  padding: 0 5px 20px;
}

.crash-blink {
  opacity: 25%;
}
